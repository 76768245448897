import React, { useRef, useEffect } from 'react';
import styles from './Topic.module.scss';

type Props = {
  title?: string,
  keyword?:string,
  children: React.Node
};

const Page = ({ title, keyword, children }: Props) => {

  const pageRef = useRef();

  useEffect(() => {
    pageRef.current.scrollIntoView();
  });

  return (
    <div ref={pageRef} className={styles['page']}>
      <div className={styles['page__inner']}>
      { title && 
        <div>
          <div className={styles['page__go_to_posts']}><a href={ '/category/' + title}>billets associés</a> </div>
        <h1 className={styles['page__title']}>{title}</h1>
        </div>
        }
        <div className={styles['page__body']}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Page;